import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { Form, Input, Modal, Select, Button } from "antd";
import { useCookies } from "react-cookie";
import { BaseUrl } from "../../../utils/config";
import axios from "axios";
import swal from "sweetalert";
import { createBrowserHistory, History } from "history";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Login.css";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const ForgotPassword = () => {
  const history = createBrowserHistory();
  const [isEmail, setIsEmail] = useState(false);
  const navigate = useHistory();

  useEffect(async () => {}, []);

  const onFinish = (values) => {
    const Fobj = {
      email: values.email,
    };

    console.log("Received values of form: ", values);
    if (!isEmail) {
      swal({
        // title: "Success!",
        text: "Please enter the Credentials",
        icon: "warning",
      });
    } else {
      axios
        .post(`${BaseUrl}/forgotpassword`, Fobj)
        .then((response) => {
          console.log("response", response);

          if (response) {
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
            });
            // setTimeout(() => {
            //   navigate({
            //     pathname: "/forgotPasswordFinal",

            //     state: { mobilenum1: values.number, country1: values.country ,email1:values.email}
            //   });
            // }, 3000)
          } else {
            console.log("response");
            // message.error(`password changed  failed`);
          }
          console.log("fetchUser");
        })
        .catch((error) => {
          console.log(error);

          swal({
            title: "User does not exist!",
            text: "Try to signup",
            icon: "error",
          });
        });
    }
  };
  const toLogin = () => {
    navigate("/login");
  };

  const toSignup = () => {
    navigate("/verifyEmail");
  };
  const toforgot = () => {
    navigate("/forgotPassword");
  };
  return (
    <div>
      {/* <GlobalStyles/> */}

      <div class="form-container">
        <div class="logo">
          <img src="./images/nitro-white.png" alt="" id="logo" />
        </div>

        <Form
          id="contact_form"
          action=""
          autocomplete="off"
          name="control-hooks"
          onFinish={onFinish}
        >
          <div class="form-head">
            <h2>Forget Password?</h2>
          </div>
          <p class="forget-text">
            Please enter your email address. You will receive a link to create a
            new password via email.
          </p>

          <div class="form-field">
            <label for="email">E-mail</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <div class="form-f">
                <Input
                  id="email"
                  className="form-control"
                  placeholder="Enter E-mail"
                  onChange={(e) => {
                    setIsEmail(e.target.value);
                  }}
                />
                {/* <i class="fa fa-user icon"></i> */}
              </div>
            </Form.Item>
          </div>

          <div class="submit-buttons">
            <input type="submit" value="Verify" class="btn-submit" />
          </div>

          <div class="copyright">
            <p>&copy; 2021 NITRO NETWORK PVT. LTD. All Rights Reserved.</p>
          </div>
        </Form>
      </div>
      {/* <Footer /> */}
    </div>
  );
};
export default ForgotPassword;
