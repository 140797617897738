import "./App.css";

import Routing from "./Routing/routing";
// import Allusers from './components/pages/users/AllUsers/AllUsers'
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import Store from "../src/redux/store";

function RouterConfig() {
  return (
    <div className="App">
      <Provider store={Store}>
        <Routing />
      </Provider>
    </div>
  );
}

export default RouterConfig;
