import { all, takeLatest, call, put } from "redux-saga/effects";

import { getPosts, getPostsStatus, renamePosts,
  renamePostsStatus,  downloadPosts,
  downloadPostsStatus,
  uploadPosts,
  uploadPostsStatus, viewPosts,
  viewPostsStatus,} from "./action";

import { getPosts as getPostsQuery,renamePosts as renamePostsQuery,
   downloadPosts as downloadPostsQuery, uploadPosts as uploadPostsQuery ,viewPosts as viewPostsQuery } from "./query";

export function* getPostsSaga(payload) {
  console.log("set",payload)
  try {
    const data  = yield call(getPostsQuery,payload.payload);
  
    const posts = data;
    console.log(posts,"post")
    yield put(getPostsStatus(data));
  } catch (err) {
    yield put(getPostsStatus(err));
    console.log(err,"error")
  }
}


export function* renamePostsSaga(payload) {
  console.log("set",payload)
  try {
    const rename  = yield call(renamePostsQuery,payload.payload);
    // const data  = yield call(getPostsQuery,payload.payload);
    const reposts = rename;
    console.log(reposts,"post")
    yield put(renamePostsStatus(reposts));
    const data  = yield call(getPostsQuery,payload.payload);
    const posts = data;
    console.log(posts,"post")
    yield put(getPostsStatus(posts));
  } catch (err) {
    yield put(renamePostsStatus(err));
    console.log(err,"error")
  }
}
export function* uploadPostsSaga(payload) {
  console.log("set",payload)
  try {
    const upload  = yield call(uploadPostsQuery,payload.payload);
    // const data  = yield call(getPostsQuery,payload.payload);
    const uploadfile = upload;
    console.log(uploadfile,"post")
    yield put(uploadPostsStatus(uploadfile));
    const data  = yield call(getPostsQuery,payload.payload);
    const posts = data;
    console.log(posts,"post")
    yield put(getPostsStatus(posts));
  } catch (err) {
    yield put(uploadPostsStatus(err));
    console.log(err,"error")
  }
}
export function* downloadPostsSaga(payload) {
  console.log("downloadPostsSaga",payload)
  try {
    // const data  = yield call(downloadPostsQuery,payload.payload);
    // const data  = yield call(getPostsQuery,payload.payload);
    const data= yield call(downloadPostsQuery,payload.payload);
  
    const posts = data;
    console.log(posts,"post")
   
    yield put(downloadPostsStatus(data));
  } catch (err) {
    yield put(downloadPostsStatus(err));
    console.log(err,"downloadPostsSagaerror")
  }
}


export default function* postsManagerSaga() {
  yield all([takeLatest(getPosts, getPostsSaga),takeLatest(renamePosts, renamePostsSaga)
    ,takeLatest(downloadPosts, downloadPostsSaga),takeLatest(uploadPosts, uploadPostsSaga)

]);
}

// yield all( [
//     takeLatest(types.CREATE_SUBSCRIBER, createSubscriber),
//     takeLatest(types.UPDATE_SUBSCRIBER, updateSubscriber),
// ])