import { handleActions } from "redux-actions";
import {
  getUser,
  getUserStatus,
  addUser,
  updateUser,
  updateUserStatus,
  addUserStatus,
  deleteUser,
  deleteUserStatus,
} from "./action";
const DEFAULT_STATE = {
  users:{},
  saveResponse:[]
};

const handlers = {
  [getUser]: state => ({ ...state, status: "PENDING" }),

  [getUserStatus]: (state, action) => {
    if (action.error) {
      console.log(action,"action")
      return { ...state, status: "ERROR" };
    }
    console.log(action,"action123")

    return { ...state, users:action.payload  };
  },
  [addUser]: state => ({ ...state, status: "PENDING" }),

  [addUserStatus]: (state, action) => {
    if (action.error) {
      return { ...state, status: "ERROR" };
    }
    console.log(action,"actionadd")

    return { ...state, saveResponse:action  };
  },
  
  [updateUser]: state => ({ ...state, status: "PENDING" }),
  
  [updateUserStatus]: (state, action) => {
    if (action.error) {
      return { ...state, status: "ERROR" };
    }
    console.log(action,"updateinreduser")

    return { ...state, saveResponse:action  };
  },
  [deleteUser]: state => ({ ...state, status: "PENDING" }),
  
  [deleteUserStatus]: (state, action) => {
    if (action.error) {
      return { ...state, status: "ERROR" };
    }
    console.log(action,"deleteUserStatus")

    return { ...state, saveResponse:action  };
  },
};

export default handleActions(handlers, DEFAULT_STATE);
