import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import ReactHighcharts from "react-highcharts";

const Dashboard = ({}) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const toSubPage = (item) => {
    history.push(item);
  };

  const noPureConfig2 = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      backgroundColor: {
        linearGradient: [0, 0, 250, 500],
        stops: [[0, "#0b111f"]],
      },
    },

    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      style: {
        color: "#F0F0F0",
      },
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    legend: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      itemStyle: {
        color: "#E0E0E3",
      },
      itemHoverStyle: {
        color: "#FFF",
      },
      itemHiddenStyle: {
        color: "#606063",
      },
      title: {
        style: {
          color: "#C0C0C0",
        },
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        // colors: pieColors,
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
          distance: -50,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    series: [
      {
        name: "Share",
        data: [
          { name: "Chrome", y: 21.41 },
          { name: "Internet Explorer", y: 11.84 },
          { name: "Firefox", y: 10.85 },
          { name: "Edge", y: 24.67 },
          { name: "Safari", y: 24.18 },
          { name: "Other", y: 7.05 },
        ],
      },
    ],
  };
  const noPureConfig1 = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",

      backgroundColor: {
        linearGradient: [0, 0, 250, 500],
        stops: [[0, "#0b111f"]],
      },
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      itemStyle: {
        color: "#E0E0E3",
      },
      itemHoverStyle: {
        color: "#FFF",
      },
      itemHiddenStyle: {
        color: "#606063",
      },
      title: {
        style: {
          color: "#C0C0C0",
        },
      },
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      style: {
        color: "#F0F0F0",
      },
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Tokyo",
        data: [
          49.9, 71.5, 106.4, 129.2, 84.0, 76.0, 135.6, 18.5, 116.4, 104.1, 95.6,
          54.4,
        ],
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // one decimal
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
    ],
  };
  const noPureConfig = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "area",
      backgroundColor: {
        linearGradient: [0, 0, 250, 500],
        stops: [[0, "#0b111f"]],
      },
    },
    accessibility: {
      description:
        "Image description: An area chart compares the nuclear stockpiles of the USA and the USSR/Russia between 1945 and 2017. The number of nuclear weapons is plotted on the Y-axis and the years on the X-axis. The chart is interactive, and the year-on-year stockpile levels can be traced for each country. The US has a stockpile of 6 nuclear weapons at the dawn of the nuclear age in 1945. This number has gradually increased to 369 by 1950 when the USSR enters the arms race with 6 weapons. At this point, the US starts to rapidly build its stockpile culminating in 32,040 warheads by 1966 compared to the USSR’s 7,089. From this peak in 1966, the US stockpile gradually decreases as the USSR’s stockpile expands. By 1978 the USSR has closed the nuclear gap at 25,393. The USSR stockpile continues to grow until it reaches a peak of 45,000 in 1986 compared to the US arsenal of 24,401. From 1986, the nuclear stockpiles of both countries start to fall. By 2000, the numbers have fallen to 10,577 and 21,000 for the US and Russia, respectively. The decreases continue until 2017 at which point the US holds 4,018 weapons compared to Russia’s 4,500.",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    legend: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      itemStyle: {
        color: "#E0E0E3",
      },
      itemHoverStyle: {
        color: "#FFF",
      },
      itemHiddenStyle: {
        color: "#606063",
      },
      title: {
        style: {
          color: "#C0C0C0",
        },
      },
    },
    xAxis: {
      allowDecimals: false,
      labels: {
        formatter: function () {
          return this.value; // clean, unformatted number for year
        },
      },
      accessibility: {
        rangeDescription: "Range: 8 nov  to 24 dec.",
      },
    },
    yAxis: {
      title: {
        text: "",
      },

      labels: {
        formatter: function () {
          return this.value / 1000 + "k";
        },
      },
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      style: {
        color: "#F0F0F0",
      },
      pointFormat:
        "{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}",
    },
    plotOptions: {
      area: {
        pointStart: 1940,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: [
      {
        name: "USA",
        data: [
          2022, 2068, 2134, 2226, 2287, 2633, 2724, 2742, 2762, 2856, 2912,
          2999, 3065, 3126, 3179, 3222, 3226, 3305, 3404, 3464, 3508, 3509,
          3557, 3637, 3701, 3744, 3786, 3880,
        ],
      },
    ],
  };

  return (
    <>
      <div class="large-view-area">
        <h2 class="heading-page">Dashboard</h2>

        <div class="home-dash">
          <div>
            <a onClick={() => toSubPage(`/nitro/dashboard`)} class="white">
              <i class="fa fa-home"></i> Home
            </a>{" "}
            /
            <a href="#" class="dull">
              Dashboard
            </a>
          </div>
        </div>
        <div class="dash-boxes">
          <div class="dash-box">
            <div>
              <p class="large">50</p>
              <p class="small">Total Users</p>
            </div>
            <i class="fa fa-user"></i>
          </div>
          <div class="dash-box">
            <div>
              <p class="large">04</p>
              <p class="small">Total Miners</p>
            </div>
            <i class="fas fa-compress-arrows-alt"></i>
          </div>
          <div class="dash-box">
            <div>
              <p class="large">10</p>
              <p class="small">Total Locations</p>
            </div>
            <i class="fas fa-map-marker-alt"></i>
          </div>
          <div class="dash-box">
            <div>
              <p class="large">45</p>
              <p class="small">Total Collections</p>
            </div>
            <i class="fas fa-box"></i>
          </div>
        </div>

        <div class="charts-four">
          <div class="chart">
            <h4 class="center">Transactions</h4>
            <div id="chart-trx">
              <ReactHighcharts config={noPureConfig} />
            </div>
          </div>

          <div class="chart">
            <h4 class="center">Miner Type</h4>
            <div id="chart">
              {" "}
              <ReactHighcharts config={noPureConfig1} />
            </div>
          </div>
          <div class="chart">
            <h4 class="center">Location</h4>
            <div id="chart-pie">
              {" "}
              <ReactHighcharts config={noPureConfig2} />
            </div>
          </div>
          <div class="chart">
            <h4 class="center">Referal chart</h4>
            <div id="chart-ref">
              {" "}
              <ReactHighcharts config={noPureConfig} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
