import { ACTION_TYPES } from "./actionTypes";

export const updateUserDetailsRedux = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_DETAILS,
    payload,
  };
};

export const viewNestId = (payload) => {
  return {
    type: ACTION_TYPES.VIEW_NEST_ID,
    payload,
  };
};
