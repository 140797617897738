import React from "react";
// import nitroLogo from "../../images/";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import nitroLogo from "../../images/nitro.jpeg";

const Collections = ({}) => {
  const { url } = useRouteMatch();

  const history = useHistory();

  const toSubPage = (item) => {
    history.push(item);
  };
  return (
    <>
      <div class="large-view-area">
        <h2 class="heading-page">Collections</h2>

        <div class="home-dash">
          <div>
            <a onClick={() => toSubPage(`/nitro/dashboard`)} class="white">
              <i class="fa fa-home"></i> Home
            </a>{" "}
            /
            <a href="#" class="dull">
              Collection
            </a>
          </div>
          <a
            onClick={() => toSubPage(`/nitro/collections/createproduct`)}
            class="create-user-btn"
          >
            Create Product
          </a>
        </div>

        <div class="marketplace-boxes">
          <div href="prouct-detail.html" class="market-box">
            <a href="#" class="image">
              <img src={nitroLogo} alt="sensor" id="image-product" />
            </a>
            <div>
              <h5>Nitro ION Miner </h5>
              <h5>450$</h5>
            </div>
            <div>
              <p>Information</p>
              <p>Last $450.00</p>
            </div>
            <div class="red">
              <p>
                <a href="#" class="edit-btn">
                  Edit
                </a>
              </p>
              <p>
                <a href="#" class="edit-btn">
                  Delete
                </a>
              </p>
            </div>
          </div>
          <div href="prouct-detail.html" class="market-box">
            <a href="#" class="image">
              <img src={nitroLogo} alt="sensor" id="image-product" />
            </a>
            <div>
              <h5>Nitro ION Miner </h5>
              <h5>450$</h5>
            </div>
            <div>
              <p>Information</p>
              <p>Last $450.00</p>
            </div>
            <div class="red">
              <p>
                <a href="#" class="edit-btn">
                  Edit
                </a>
              </p>
              <p>
                <a href="#" class="edit-btn">
                  Delete
                </a>
              </p>
            </div>
          </div>

          <div href="prouct-detail.html" class="market-box">
            <a href="#" class="image">
              <img src={nitroLogo} alt="sensor" id="image-product" />
            </a>
            <div>
              <h5>Nitro ION Miner </h5>
              <h5>450$</h5>
            </div>
            <div>
              <p>Information</p>
              <p>Last $450.00</p>
            </div>
            <div class="red">
              <p>
                <a href="#" class="edit-btn">
                  Edit
                </a>
              </p>
              <p>
                <a href="#" class="edit-btn">
                  Delete
                </a>
              </p>
            </div>
          </div>
          <div href="prouct-detail.html" class="market-box">
            <a href="#" class="image">
              <img src={nitroLogo} alt="sensor" id="image-product" />
            </a>
            <div>
              <h5>Nitro ION Miner </h5>
              <h5>450$</h5>
            </div>
            <div>
              <p>Information</p>
              <p>Last $450.00</p>
            </div>
            <div class="red">
              <p>
                <a href="#" class="edit-btn">
                  Edit
                </a>
              </p>
              <p>
                <a href="#" class="edit-btn">
                  Delete
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collections;
