import { handleActions } from "redux-actions";
import {
  getPosts,
  getPostsStatus,
  renamePosts,
  renamePostsStatus,
  statusUnset,
  createPostLocaly,
  downloadPosts,
  downloadPostsStatus,
  uploadPosts,
  uploadPostsStatus,
  viewPosts,
  viewPostsStatus,
} from "./action";

const DEFAULT_STATE = {
  newAction: ["testfzxb"],
  status: "",
  items: [],
  url:"",
};

const handlers = {
  [getPosts]: state => ({ ...state, status: "PENDING" }),

  [getPostsStatus]: (state, action) => {
    console.log(action,"action")
    if (action.error) {
      return { ...state, status: "ERROR" };
    }

    const users = { ...action.payload};

    const items = users;

    console.log(items,"itemslog")
    return { ...state, status: "DONE", items };
  },
  [renamePosts]: state => ({ ...state, status: "PENDING" }),

  [renamePostsStatus]: (state, action) => {
    console.log(action,"action")
    if (action.error) {
      return { ...state, status: "ERROR" };
    }

    const users = { ...action.payload};

    const items = users;

    console.log(items,"itemslog")
    return { ...state, status: "DONE", items };
  },
  [downloadPosts]: state => ({ ...state, status: "PENDING" }),

  [downloadPostsStatus]: (state, action) => {
    console.log(action,"downloadPostsStatus")
    if (action.error) {
      return { ...state, status: "ERROR" };
    }

    const users = { ...action.payload};

    const items = users;

    console.log(items,"itemslog")
    return { ...state, status: "DONE", items };
  },
  [uploadPosts]: state => ({ ...state, status: "PENDING" }),

  [uploadPostsStatus]: (state, action) => {
    console.log(action,"uploadPostsStatus")
    if (action.error) {
      return { ...state, status: "ERROR" };
    }

    const users = { ...action.payload};

    const items = users;

    console.log(items,"itemslog")
    return { ...state, status: "DONE", items };
  },
  [viewPosts]: state => ({ ...state, status: "PENDING" }),

  [viewPostsStatus]: (state, action) => {
    console.log(action,"viewPostsStatus")
    if (action.error) {
      return { ...state, status: "ERROR" };
    }
    window.localStorage.setItem("login",action.payload.username);
    const users = { ...action.payload};

    const items = users;

    console.log(items,"items viewPostsStatus")
    return { ...state, status: "DONE", items };
  },
  [createPostLocaly]: (state, action) => {
    let finalAction = [];
    if (state.newAction !== undefined) {
      finalAction = [...state.newAction, action.payload];
    } else {
      finalAction = [action.payload];
    }
    return { ...state, newAction: finalAction };
  },

  [statusUnset]: state => {
    return { ...state, status: "" };
  }
};

export default handleActions(handlers, DEFAULT_STATE);
