import { all, takeLatest, call, put } from "redux-saga/effects";

import { getLogin, getLoginStatus ,getLogout,getLogoutStatus} from "./action";

import {loginUserService ,logoutUserService} from "./query";

export function* getLoginSaga(payload) {
  console.log("payload",payload.payload)

  try {
    const data  = yield call(loginUserService,payload.payload);
    
    
    console.log(data.token,"resp_red")
    yield put(getLoginStatus(data));
  } catch (err) {
    yield put(getLoginStatus(err));
  }
}
export function* getLogoutSaga(payload) {
  console.log("payloadlogout",payload.payload)

  try {
    const data  = yield call(logoutUserService,payload.payload);

    console.log(data,"resp_redlogout")
    yield put(getLogoutStatus(data));
  } catch (err) {
    yield put(getLogoutStatus(err));
  }
}

export default function* postsManagerSaga() {
  yield all([takeLatest(getLogin, getLoginSaga),takeLatest(getLogout,getLogoutSaga)


  ]);

}

