import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useHistory, useLocation, Link } from "react-router-dom";
import { BaseUrl } from "../../../utils/config";
import axios from "axios";
import { Form, Input, Modal, Select } from "antd";
import "./Login.css";

// import MainImg from "../../images/undraw1.svg";
const ForgotPasswordFinal = () => {
  const [isEmail, setIsEmail] = useState(null);
  const [isPass, setIsPass] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isCPass, setIsCPass] = useState(null);
  const [passErrorMsg, setPassErrorMsg] = useState(null);
  const [token, setToken] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  useEffect(() => {
    let path = location.pathname;
    let temp = path.substring(path.lastIndexOf("/") + 1);
    setToken(temp);
    callForPreParams(temp);
  }, [location]);

  const verifyPassWord = () => {
    if (isPass != null && isCPass != null) {
      isPass != isCPass
        ? setPassErrorMsg("The two passwords that you entered do not match!")
        : setPassErrorMsg(null);
    }
  };
  const callForPreParams = (temp) => {
    axios
      .get(`${BaseUrl}/resetpassword/${temp}`)
      .then((response) => {
        console.log("response", response?.data?.data?.email);
        if (response) {
          console.log("response11", response?.data);

          swal({
            title: "Success!",
            text: response?.data?.message,
            icon: "success",
          });
          setIsEmail(response?.data?.data?.email);
          setUserId(response?.data?.data?.id);
          form.setFieldsValue({
            email: response?.data?.data?.email,
          });
        } else {
          swal({
            title: "Invalid Credentials",
            text: "Please try again",
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        swal({
          title: "Invalid Token!",
          text: "Please try again",
          icon: "warning",
        });
      });
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    const Fobj = {
      password: values.cpassword,
    };
    if (
      values.password == null ||
      values.cpassword == null ||
      values.cpassword != values.password ||
      values.password == "" ||
      values.cpassword == ""
    ) {
      swal({
        // title: "Invalid Token!",
        text: "Please enter the Credentials",
        icon: "warning",
      });
    } else {
      axios
        .put(`${BaseUrl}/updatepassword/${userId}/${token}`, Fobj)
        .then((response) => {
          console.log("response", response?.data);

          if (response) {
            swal({
              // title: "Invalid Token!",
              text: response?.data?.message,
              icon: "success",
            });

            setTimeout(() => {
              history.push("/login");
            }, 3000);
          } else {
            console.log("response");
          }
          console.log("fetchUser");
        })
        .catch((error) => {
          console.log(error);

          swal({
            title: "Invalid Credentials",
            text: "Please try again",
            icon: "warning",
          });
        });
    }
  };
  const toLogin = () => {
    history.push("/login");
  };

  const toSignup = () => {
    history.push("/verifyEmail");
  };
  const toforgot = () => {
    history.push("/forgotPassword");
  };

  return (
    <>
      <div>
        <div className="form-container">
          {/* <div className="btn-switch">
            <a onClick={toLogin} className="">
              Sign In
            </a>
            <a onClick={toSignup} className="">
              Sign Up
            </a>
            <a className="active" onClick={toforgot}>
              Forgot Password?
            </a>
          </div> */}
          <Form
            // className="login-from"
            autoComplete="off"
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <div className="form-head">
              <h2>Forgot Password</h2>
            </div>

            <div className="form-field">
              <label for="email">E-mail</label>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <div class="form-f">
                  <Input
                    id="email"
                    placeholder="Enter E-mail"
                    disabled
                    className="form-control"
                    onChange={(e) => {
                      setIsEmail(e.target.value);
                    }}
                  />
                  {/* <i class="fa fa-user icon"></i> */}
                </div>
              </Form.Item>
            </div>

            <div className="form-field">
              <label for="password">New Password</label>
              <Form.Item name="password">
                <div class="form-f">
                  <Input.Password
                    className="form-control"
                    size="large"
                    type="password"
                    placeholder="New Password"
                    onChange={(e) => {
                      setIsPass(e.target.value);
                    }}
                    onMouseOut={() => {
                      verifyPassWord();
                    }}
                  />
                  {/* <i class="fa fa-key icon"></i> */}
                </div>
              </Form.Item>
              {/* <input type="password" name="password" id="password" placeholder="New Password" /> */}
            </div>
            <div className="form-field">
              <label for="cpassword">Confirm Password</label>
              <Form.Item name="cpassword">
                <div class="form-f">
                  <Input.Password
                    className="form-control"
                    size="large"
                    type="password"
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      setIsCPass(e.target.value);
                    }}
                    onMouseOut={() => {
                      verifyPassWord();
                    }}
                  />
                  {/* <i class="fa fa-key icon"></i> */}
                </div>
              </Form.Item>
              <p className="passErrorMsg">{passErrorMsg}</p>
            </div>
            <div class="submit-buttons">
              <input type="submit" disabled value="submit" class="btn-submit" />
            </div>
          </Form>
        </div>

        <div className="svg">{/* <img src={MainImg} alt="" /> */}</div>
      </div>
    </>
  );
};

export default ForgotPasswordFinal;
