import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
const MydeviceMenu = ({}) => {
  const history = useHistory();

  const toSubPage = (item) => {
    history.push(item);
  };

  return (
    <>
      <div class="sidebar">
        <ul>
          <li class="hide-on-big">
            <p href="#">Welcome USer</p>
          </li>
          <li
            class={
              history.location.pathname.includes("nitro/dashboard")
                ? "active first-link"
                : "first-link"
            }
          >
            <div onClick={() => toSubPage(`nitro/dashboard`)}>Dashboard</div>
          </li>
          <li
            class={history.location.pathname.includes("/mydevice") && "active"}
          >
            <div onClick={() => toSubPage(`/mydevice`)}>My Devices</div>
          </li>
          <li
            class={history.location.pathname.includes("/profile") && "active"}
          >
            <div onClick={() => toSubPage(`/profile`)}>Profile</div>
          </li>
          <li class="hide-on-big">
            <div href="login.html">Logout</div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MydeviceMenu;
