import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { Form, Input, Modal, Select, Tooltip } from "antd";

const Transactions = ({}) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const toSubPage = (item) => {
    history.push(item);
  };
  return (
    <>
      <div class="large-view-area">
        <h2 class="heading-page">Transactions</h2>

        <div class="home-dash">
          <div>
            <a onClick={() => toSubPage(`/nitro/dashboard`)} class="white">
              <i class="fa fa-home"></i> Home
            </a>{" "}
            /
            <a href="transactions.html" class="dull">
              Transactions
            </a>
          </div>
        </div>

        <div class="user-table mt-6">
          <table class="table table-fluid" id="myTable">
            <thead>
              <tr>
                <th class="rd">Name</th>
                <th>Email address</th>
                <th>Country</th>
                <th>Referral code</th>

                <th class="right-field">Type of miner </th>
                <th class="right-field">No.of miners </th>
                <th class="right-field">Amount Paid</th>
                <th class="right-field">Source</th>
                <th class="right-field">Transaction ID</th>
                {/* <!-- <th>Actions</th> --> */}
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>John Smith</td>
                <td class="r">nitro@network.com</td>
                <td class="right-field">India</td>
                <td class="right-field">0f3er5</td>
                <td class="right-field">Nitro Bed</td>
                <td class="right-field">5</td>
                <td class="right-field">$ 1800</td>
                <td class="right-field">Paypal</td>
                <td class="right-field">TRX10008781087413</td>
              </tr>
              <tr>
                <td>John Smith</td>
                <td class="r">nitro@network.com</td>
                <td class="right-field">India</td>
                <td class="right-field">0f3er5</td>
                <td class="right-field">Nitro Light</td>
                <td class="right-field">5</td>
                <td class="right-field">$ 1800</td>
                <td class="right-field">Paypal</td>
                <td class="right-field">TRX10008781087413</td>
              </tr>
            </tbody>
          </table>
          <table class="table de-table table-rank table-font-size-reduce table-responsive">
            <thead>
              <tr>
                {/* <!-- Added new Class --> */}
                {/* <th scope="col" class="first-head">Collection</th> */}
                <th scope="col" class="right">
                  Source{" "}
                  <Tooltip title="Source">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </th>

                <th scope="col" class="right">
                  Type of transaction{" "}
                  <Tooltip title="Type of transaction">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </th>

                <th scope="col" class="right">
                  Transaction ID{" "}
                  <Tooltip title="Transaction ID">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </th>

                <th scope="col" class="right">
                  Timestamp{" "}
                  <Tooltip title="Timestamp">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </th>

                <th scope="col" class="right">
                  Amount{" "}
                  <Tooltip title="Amount">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </th>

                <th scope="col" class="right">
                  Status{" "}
                  <Tooltip title="Status">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </th>
              </tr>
            </thead>
            {/* <tbody>
              {allproducts.length > 0 ? (
                allproducts.map((item) => {
                  return (
                    <tr>
                      <td scope="row" data-label="">
                        <span class="address-text-table">{item.source}</span>
                      </td>
                   
                      <td class="right" data-label="Type of miner">
                        {item.source}
                      </td>
                     
                      <td class="d-plus right" data-label="long">
                        {item.charge_id}
                      </td>
                      <td class="right max-time" data-label="timestamp">
                        <span class="time-font">{item.updatedAt}</span>
                      </td>
                      <td class="right" data-label="status">
                        {item.amount}
                      </td>
                      <td class="right" data-label="kyc status">
                        {item.payment_status}
                      </td>
                    </tr>
                  );
                })
              ) : (
                //  <tr ><h4 className='form-head'></h4></tr>}
                <td colSpan={6}>No Data </td>
              )}
            </tbody> */}
          </table>
        </div>
      </div>
    </>
  );
};

export default Transactions;
