import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import MydeviceMenu from "../../mainmenu/mydeviceMenu";
import Sensor from "../../images/sensor.png";

const Mydevice = ({}) => {
  return (
    <>
      <div class="dashboard-container">
        <MydeviceMenu />

        <div class="large-view-area">
          {/* <!-- <h3 class="head-section">My Devices</h3> --> */}

          <div class="user-table mt-6">
            <table class="table table-fluid" id="myTable">
              <thead>
                <tr>
                  <th>Device</th>
                  <th class="rd">Name</th>
                  <th class="right-field">Qty</th>
                  <th class="right-field">Amount</th>
                  <th>Status</th>
                  {/* <!-- <th>Actions</th> --> */}
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <img src={Sensor} alt="" id="device-image-small" />
                  </td>
                  <td class="r">Nitro Miner</td>
                  <td class="right-field">$ 1</td>
                  <td class="right-field">1800</td>
                  <td class="right-field">Active</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mydevice;
