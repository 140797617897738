import React, { useState, useEffect } from "react";
import "./main.css";
import { GiHamburgerMenu } from "react-icons/gi";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import SubMenu from "./submenu";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as CgIcons from "react-icons/cg";
import * as BiIcons from "react-icons/bi";
import * as FcIcons from "react-icons/fc";
import { Menu, Dropdown } from "antd";
import nitroLogo from "../images/nitro.jpeg";
import nitroWhite from "../images/nitrowhite.png";
import { getLogout } from "../redux/login/action";
import { getPosts } from "../redux/posts/action";
import Collections from "../components/pages/collections";
import CreateProduct from "../components/pages/createProduct";
import CreateUser from "../components/pages/createUser";
import Dashboard from "../components/pages/dashboard";
import Nest from "../components/pages/nest";
import Transactions from "../components/pages/transactions";
import Users from "../components/pages/users";
import ViewNest from "../components/pages/viewNest";
const SidebarNav = styled.nav`
  color: #fff;
`;
const SidebarWrap = styled.div`
  width: 100%;
`;

function Mainmenu({ dispatch, response }) {
  useEffect(async () => {
    dispatch(getPosts(response?.payload));
  }, []);

  const loginUser = localStorage.getItem("login");
  const loginGender = localStorage.getItem("loginGender");
  const loginRole =
    localStorage.getItem("loginRole") &&
    localStorage.getItem("loginRole").substr(5);
  const { url } = useRouteMatch();

  const [showNav, setShowNav] = useState(false);
  const history = useHistory();
  const menuItems = [
    // {
    //   title: "Dashboard",
    //   path: `${url}/dashboard`,
    //   icon: <i class="fas fa-chart-line"></i>,
    // },
    // {
    //   title: "Users",
    //   path: `${url}/users`,
    //   icon: <i class="fa fa-users"></i>,
    // },
    // {
    //   title: "Locations",
    //   path: `${url}/locations`,
    //   icon: <i class="fas fa-map-marker-alt"></i>,
    // },
    // {
    //   title: "Transactions",
    //   path: `${url}/transactions`,
    //   icon: <i class="fas fa-receipt"></i>,
    // },
    // {
    //   title: "Collections",
    //   path: `${url}/collections`,
    //   icon: <i class="fas fa-box"></i>,
    // },
    {
      title: "Nest",
      path: `${url}/nest`,
      icon: <i class="fas fa-toggle-off"></i>,
    },
    {
      title: "Logout",
      path: `/login`,
      icon: <i class="fa fa-sign-out-alt"></i>,
    },
  ];
  const logout = () => {
    dispatch(getLogout(response.payload));
    window.localStorage.setItem("login", null);
    // window.localStorage.setItem("loginRole",null);
  };

  const toChangePwd = () => {
    {
      history.push("/changePassword");
    }
  };

  const toSubPage = (item) => {
    history.push(item);
  };

  // if (loginUser === null || response?.payload?.authorities === null) {
  //   {
  //     history.push("/login");
  //   }
  // }

  console.log("loginGender", history.location.pathname);
  return (
    <div>
      {" "}
      <div class="background">
        <nav class="showing">
          {/* <!-- <i class="fa fa-bars"></i> --> */}
          <div class="logo">
            <img src={nitroWhite} alt="" id="logo" />
          </div>

          {/* <!-- <div class="df aic"> --> */}
          {/* 
          <div class="side-link mneu">
            <a href="#" class="white open-drop">
              Admin <i class="fa fa-caret-down"></i>
            </a>
            <div class="dropdown">
              <ul>
                <li>
                  <a href="#">
                    <i class="fa fa-cogs"></i> Setting
                  </a>
                </li>

                <li>
                  <a href="#">
                    <i class="fa fa-sign-out-alt"></i> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div class="bar-btn">
            <img
              src="https://img.icons8.com/material-rounded/24/ffffff/circled-menu.png"
              class="fa-bars"
            />
          </div>
        </nav>
      </div>
      <div class="dashboard-container">
        <div class="sidebar">
          <ul>
            <li class="hide-on-big">
              <div href="#">Welcome USer</div>
            </li>
            <li
              class={
                history.location.pathname.includes("nitro/dashboard")
                  ? "active first-link"
                  : "first-link"
              }
            >
              {/* <div onClick={() => toSubPage(`${url}/dashboard`)}>
                <i class="fas fa-chart-line"></i> Dashboard
              </div> */}
            </li>
            {menuItems.map((item) => {
              return (
                <li
                  class={
                    history.location.pathname.includes(item.path) && "active"
                  }
                >
                  <div onClick={() => toSubPage(item.path)}>
                    {item.icon}
                    {item.title}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <div>
          <Route
            path={`${url}/collections`}
            exact
            component={Collections}
          ></Route>
          <Route
            path={`${url}/collections/createproduct`}
            exact
            component={CreateProduct}
          ></Route>
          <Route
            path={`${url}/users/createuser`}
            exact
            component={CreateUser}
          ></Route>
          <Route
            path={`${url}/transactions`}
            exact
            component={Transactions}
          ></Route>
          <Route path={`${url}/users`} exact component={Users}></Route>
          <Route
            path={[`${url}/nest`, `${url}`]}
            exact
            component={Nest}
          ></Route>
          <Route
            path={`${url}/nest/viewNest`}
            exact
            component={ViewNest}
          ></Route>
          <Route
            path={[`${url}/dashboard`]}
            exact
            component={Dashboard}
          ></Route>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    response: state.login.response,
  };
};
export default connect(
  mapStateToProps,
  null // Generaly its the place of mapStateToDispatch
)(Mainmenu);
