import React, {useState} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import "./main.css";
import { useLocation  } from "react-router-dom";
const SidebarLink = styled(Link)`

display:felx;
color:#C1C1C1;
justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    list-style: none;
    height: 45px;
    text-decoration: none;
    font-size: 16px;
   
`;
const DropdownLink = styled(Link)`
display:felx;
color:#C1C1C1;
padding: 7px 0px 0px 20px;
list-style: none;
height: 40px;
margin: auto 0px;
text-decoration: none;
font-size: 16px;
&:hover{
    background:#252531;
    border-left:4px solid #00AAC1;
    cursor:pointer;
    Color:#ffffff;
        }
    &:focus{
        background:#252531;
        border-left:4px solid #00AAC1;
        cursor:pointer;
        Color:#ffffff;
    }
`;
const SidebarLable = styled.span`
margin: 0px 0px 0px 16px;
`;
 const SubMenu =({item, showNav})=>{
    const [subnavicon, setSubnavicon]=  useState(false);
    const [currentName, setCurrentName]=  useState("/home");
    const history = useLocation();
    const showSubnav =(value)=>{
        item.subNav && setSubnavicon(!subnavicon);
        setCurrentName(value)
    } 
    return(
        <div>
            <SidebarLink to={item.path} 
            onClick={()=> showSubnav(item.path) }     className={(currentName && history.pathname)==item.path?"navBarNameActive":"navBarName"}>
                 <span className='sidenavIcon'> {item.icon} </span>
<SidebarLable className={showNav?"sidenavTitle":"SidenavHide"}>{item.title}</SidebarLable>

<div className={showNav?"sidenavOpen":"SidenavHide"}> 
{item.subNav && subnavicon ? item.iconOpend:
               item.subNav && !subnavicon?item.iconClosed:null}
</div>
            </SidebarLink>
            {subnavicon && showNav && item?.subNav?.map((subitem,index)=>{
                return(
                    <DropdownLink  className={history.pathname==subitem.path?"navBarNameActive":"navBarName"}  to={subitem.path} ket={index}>
                       
                        <SidebarLable onClick={()=>setCurrentName(subitem.path)}> <span className="subTitle"> {subitem.title}</span></SidebarLable>
                    </DropdownLink>
                )
            })}
        </div>
    )
 }
  export default SubMenu;