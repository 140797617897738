import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import swal from "sweetalert";
import { BaseUrl } from "../../utils/config";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";
import {
  setConnectedWallet,
  setStakeTokenData,
  viewNestId,
  updateUserDetailsRedux,
} from "../../redux/actions";
const Nest = ({ viewNestId }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { Search } = Input;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [allproducts, setAllproducts] = useState([]);

  useEffect(() => {
    // $("#myTable").DataTable({});
    // const isTemp = localStorage.getItem("loginUserId");
    axios
      .get(`${BaseUrl}/getNestingPlaces`)
      .then((response) => {
        console.log("textnest", response.data.data);
        if (response) {
          setAllproducts(response.data.data);
        } else {
        }
      })
      .catch((error) => {
        swal({
          title: "Invalid credentials",
          text: "Please try again",
          icon: "error",
        });
      });
  }, [location]);

  const toSubPage = (item) => {
    history.push(item);
  };
  const toViewPage = (item, id) => {
    viewNestId({ viewId: id });
    history.push({
      pathname: item,
      nestId: {
        id: id,
      },
    });
  };
  const onSearch = (value) => console.log(value);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    console.log("hanSearch", dataIndex);
  };

  const handleReset = (clearFilters, selectedKeys, dataIndex, confirm) => {
    clearFilters();
    // confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${
            dataIndex === "lat"
              ? "latitude"
              : dataIndex === "verification_status"
              ? "status"
              : dataIndex === "lang"
              ? "longitude"
              : dataIndex === "miner_name"
              ? "type of miner"
              : dataIndex
          }`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(clearFilters, selectedKeys, dataIndex, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      width: "10%",
      ...getColumnSearchProps("email"),
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "20%",
      ...getColumnSearchProps("address"),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Type of miner",
      dataIndex: "miner_name",
      key: "miner_name",
      // width: "20%",
      ...getColumnSearchProps("miner_name"),
    },
    {
      title: "Latitude",
      dataIndex: "lat",
      key: "lat",
      // width: "20%",
      ...getColumnSearchProps("lat"),
    },
    {
      title: "Longitude",
      dataIndex: "lang",
      key: "lang",
      // width: "20%",
      ...getColumnSearchProps("lang"),
    },
    {
      title: " KYC Status",
      dataIndex: "verification_status",
      key: "verification_status",
      // width: "20%",
      ...getColumnSearchProps("verification_status"),
      render: (_, record) => (
        <p>
          {record.verification_status ? record.verification_status : "Pending"}
        </p>
        // <a
        //   onClick={() => toViewPage(`/nitro/nest/viewNest`, record.place_id)}
        //   class="create-user-btn"
        // >
        //   view
        // </a>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      // width: "20%",
      render: (_, record) => (
        <a
          onClick={() => toViewPage(`/nitro/nest/viewNest`, record.place_id)}
          class="create-user-btn"
        >
          view
        </a>
      ),
    },
  ];
  return (
    <>
      <div class="large-view-area">
        <h2 class="heading-page">Nest</h2>

        <div class="home-dash">
          <div>
            <a onClick={() => toSubPage(`/nitro/dashboard`)} class="white">
              <i class="fa fa-home"></i> Home
            </a>{" "}
            /
            <a href="Nest.html" class="dull">
              Nest
            </a>
          </div>
        </div>
        <Table columns={columns} dataSource={allproducts} />
        {/* <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{
            width: 250,
          }}
        /> */}
        {/* <div class="user-table mt-6">
          <table
            class="table table-fluid"
            id="myTable"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th class="rd">Email address</th>
                <th class="max-width">Address</th>
                <th class="right-field">Type of miner</th>
                <th class="right-field">Latitude</th>

                <th class="right-field">Longitude</th>
                <th class="right-field">Status</th>
                <th class="right-field">Actions</th>
              </tr>
            </thead>

            <tbody>
              {allproducts.map((item) => {
                return (
                  <tr>
                    <td class="r">{item.email}</td>
                    <td class="r">{item.address}</td>
                    <td class="right-field">{item.miner_name}</td>
                    <td class="right-field">{item.lat}</td>
                    <td class="right-field">{item.lang}</td>
                    <td class="right-field green">
                      {item.verification_status === "pending" ? (
                        <i
                          class="fa fa-exclamation-circle"
                          // aria-hidden="true"
                        ></i>
                      ) : (
                        <i class="fa fa-spinner"></i>
                        // <i class="fa fa-check-circle"></i>
                      )}{" "}
                      {item.verification_status}
                    </td>
                    <td class="right-field">
                      {" "}
                      <a
                        onClick={() =>
                          toViewPage(`/nitro/nest/viewNest`, item.place_id)
                        }
                        class="create-user-btn"
                      >
                        view
                      </a>
                    </td>
                  </tr>
                );
              })}

              {/* <tr>
                <td class="r">nitro@network.com</td>
                <td class="max-width" style={{ width: "10%" }}>
                  South Poll, Western, Newyork , 1105
                </td>
                <td class="right-field">Nitro Ion Bed</td>
                <td class="right-field">32'56</td>
                <td class="right-field">70'21</td>
                <td class="right-field yellow">
                  <i class="fa fa-spinner"></i> In Progress
                </td>
                <td class="right-field">
                  {" "}
                  <a
                    onClick={() => toSubPage(`/nitro/nest/viewNest`)}
                    class="create-user-btn"
                  >
                    view
                  </a>
                </td>
              </tr> 
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    viewNestId: (payload) => dispatch(viewNestId(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Nest);
