import React, { useState, useEffect } from "react";
// import "./styles/nitroStyles.css";
import { Form, Input, Modal, Select, Button } from "antd";
import { BaseUrl } from "../../../utils/config";
import { useCookies } from "react-cookie";
import axios from "axios";
import swal from "sweetalert";
import "./Login.css";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  setConnectedWallet,
  setStakeTokenData,
  viewNestId,
  updateUserDetailsRedux,
} from "../../../redux/actions";
// "../../redux/actions";

const Logintwo = ({ updateUserDetails }) => {
  const history = useHistory();
  const [isPass, SetIsPass] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isTemp, setTemp] = useState(false);
  const location = useLocation();
  const [error, setError] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  // window.navigateState(null, document.title, window.location.href);
  const locationFrom = localStorage.getItem("locationFrom");
  const locationFromPId = localStorage.getItem("locationFromPId");
  useEffect(async () => {
    let path = location.pathname;
    let temp = path.substring(path.lastIndexOf("/") + 1);

    // localStorage.clear();
    const params = new URLSearchParams(window.location.search); // id=123
    let id = params.get("token");

    console.log("token", id); //123
    id && window.localStorage.setItem("nestToken", id);
    temp === "login"
      ? setTemp(localStorage.getItem("nestToken"))
      : pushLogin(id);
    pushLogin(id);
    removeCookie("user");
  }, []);

  const pushLogin = (id) => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("token")) {
      setError("There was a problem.");
      queryParams.delete("token");
      history.replace({
        search: queryParams.toString(),
      });
    }
  };
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    const Fobj = {
      email: values.email,
      password: values.Password,
    };

    if (
      values.email == null ||
      values.Password == null ||
      values.Password == ""
    ) {
      swal({
        // title: "Good job!",
        text: "Please enter the Credentials",
        icon: "warning",
      });
    } else {
      if (
        values.email == "admin@nitro.network" &&
        values.Password == "Admin@123"
      ) {
        updateUserDetails({
          loginUser: "Admin",
        });

        swal({
          title: "Success!",
          text: "You are logged in",
          icon: "success",
        });
        setTimeout(() => {
          history.push("/nitro");
        }, 3000);
      } else {
        swal({
          title: "Invalid credentials",
          text: "Please try again",
          icon: "error",
        });
      }
      // dispatch(getLogin(values));
      //   axios
      //     .post(`${BaseUrl}/login`, Fobj)
      //     .then((response) => {
      //       console.log("response", response.data);

      //       if (response) {
      //         window.localStorage.setItem("loginUser", response.data.data.name);
      //         // window.localStorage.setItem("loginUserRewards", response); history.goBack()
      //         window.localStorage.setItem("loginUserId", response.data.data.id);
      //         window.localStorage.setItem(
      //           "loginUserMail",
      //           response.data.data.email
      //         );
      //         window.localStorage.setItem(
      //           "loginUserpending",
      //           response.data.data.is_payment_pending
      //         );
      //         window.localStorage.setItem(
      //           "loginUserPreminer",
      //           response.data.data.is_preminer
      //         );
      //         window.localStorage.setItem(
      //           "loginUserToken",
      //           response.data.data.token
      //         );
      //         isTemp &&
      //           callForNest(
      //             values.email,
      //             response.data.data.id,
      //             response.data.data.token
      //           );
      //         // setTimeout(() => {
      //         //   response.data.data.is_payment_pending
      //         //     ? navigate("/pendingpayments")
      //         //     : locationFrom === "productDetails"
      //         //     ? navigate({
      //         //         pathname: `/productDetails`,

      //         //         state: { selectedId: locationFromPId },
      //         //       })
      //         //     : navigate("/mynest");
      //         // }, 3000);
      //         swal({
      //           title: "Success!",
      //           text: "You are logged in",
      //           icon: "success",
      //         });
      //       } else {
      //         console.log("response");
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       swal({
      //         title: "Invalid credentials",
      //         text: "Please try again",
      //         icon: "error",
      //       });
      //     });
    }
  };
  const callForNest = (email, userid, userToken) => {
    const Fobj = {
      email: email,
      user_id: userid,
    };
    axios
      .post(`${BaseUrl}/addplace/${isTemp}`, Fobj, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        console.log("response", response.data);

        if (response) {
          console.log("response", response.data);
        } else {
          console.log("response");
        }
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "Invalid credentials",
          text: "Please try again",
          icon: "error",
        });
      });
  };

  const toLogin = () => {
    // navigate("/login")
  };

  const toSignup = () => {
    // navigate("/verifyEmail");
  };
  const toforgot = () => {
    history.push("/forgotPassword");
  };
  return (
    <div>
      <div class="form-container">
        <div class="logo">
          <img src="./images/nitro-white.png" alt="" id="logo" />
        </div>
        <Form
          name="contactForm"
          id="contact_form"
          className=""
          action=""
          autocomplete="off"
          onFinish={onFinish}
        >
          <div class="form-head">
            <h2>Signin</h2>
          </div>

          <div class="form-field">
            <label for="email">E-mail</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <div class="form-f">
                <Input
                  id="email"
                  placeholder="Enter E-mail"
                  onChange={(e) => {
                    setIsEmail(e.target.value);
                  }}
                />
                {/* <i class="fa fa-user icon"></i> */}
              </div>
            </Form.Item>
          </div>

          <div class="form-field">
            <label for="password">Password</label>
            <Form.Item
              name="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                className="form-control"
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  SetIsPass(e.target.value);
                }}
              />
              {/* <i class="fa fa-key icon"></i> */}
            </Form.Item>
          </div>

          <div className="textInitial">
            <input type="checkbox" name="remember" id="remember" />
            <label for="remember"> Remember Me</label>
          </div>

          <div class="submit-buttons">
            <a onClick={toforgot} class="mr-3">
              Forget password?
            </a>
            <input type="submit" value="Signin" class="btn-submit" />
          </div>
          {/* <div class="create-account">
            Don't have an account ?<a onClick={toSignup}>Create Account</a>
          </div> */}

          <div class="copyright">
            <p>&copy; 2021 NITRO NETWORK PVT. LTD. All Rights Reserved.</p>
          </div>
        </Form>
      </div>

      <section className="container">
        <div className="row">
          <div className="spacer-double"></div>
          <div className="col-md-6 offset-md-3"></div>
        </div>
      </section>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDetails: (payload) => dispatch(updateUserDetailsRedux(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Logintwo);
