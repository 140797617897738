import { all } from "redux-saga/effects";
import posts from "./posts/saga";
import login from "./login/saga";
import addUser from "./users/saga";

function* rootSaga() {
  yield all([posts(),login(),addUser()]);
}

export default rootSaga;
