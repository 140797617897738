import {BaseUrl} from "../../utils/config";
import axios from "axios";
import { message} from 'antd';
export const getPosts = async () => {
    // console.log(values,"ROLE_ADMIN","valuesvalu333esvalues")
    const name = localStorage.getItem('login');
    const token =localStorage.getItem('loginToken');
       const role = localStorage.getItem('loginRole')
  //  const  =values?.username;
  //      const  = values?.token;
  //      const role =values?.authorities[0].authority;
   try{
    if(role=="ROLE_ADMIN"){
        const response = await fetch(`${BaseUrl}/allfiles`,{
            headers: {
                'Authorization': `Bearer ${token}` ,
              },
        });
        const data = await response.json();
       console.log(data,"ddd")
       
       return data;
    }else{
    const response = await fetch(`${BaseUrl}/files/${name}`,{
      headers: {
          'Authorization': `Bearer ${token}` ,
        },
  });
  const data = await response.json();
 console.log(data,"ddd")
 
 return data;
}
}
catch(e){
    console.log("ee",e)
}


}

export const renamePosts = values =>{
    console.log(" usersupdate",values);
    const token =localStorage.getItem('loginToken');
    return axios.get(`${BaseUrl}/renamingFile/${values.newName}?fileType=${values.fileType}&fileName=${values.oldfilename}&rolename=${values.rolename}&username=${values.editUser}`,{
        headers: {
            // 'Content-Type': 'application/json',
            // 'accept': 'application/json, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'Authorization': `Bearer ${token}` ,
          },}
    )
    .then( (response) => {
        console.log("update", response)
        message.success(`${values.fileType} uploaded Successfully`);
       return   (response?.data);
      
        
      })
      .catch( (error) => {
        console.log("22error",error);
        // message.error(`${values.fileType} uploaded faild`);
      });  }

      export const downloadPosts = values =>{
        const token =localStorage.getItem('loginToken');
        console.log(" usersupdate",values);
        return window.open(`${BaseUrl}/anyfile?fileType=${values.fileType}&fileName=${values.oldfilename}&rolename=${values.rolename}&username=${values.editUser}`,{
            // headers: {
            //     'Content-Type': 'application/json',
            //     // 'accept': 'application/json, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
            
            //     'Authorization': `Bearer ${values.token}` ,
            //   },
        }
        )
        .then( (response) => {
            console.log("update", response);
            message.success(`${values.fileType} Downloaded Successfully`);
           return   response?.data;
            
          })
          .catch( (error) => {
            console.log(error);
          });  }

export const uploadPosts = async (values) => {
  const token =localStorage.getItem('loginToken');
  const name = localStorage.getItem('login');
    console.log(values,"ROLE_ADMIN","valuesvaluesvalues");
    

  return axios.post(`${BaseUrl}/upload/${values.fileType}?rolename=${values.rolename}&username=${name}`,values.values.file,{
        headers: {
            // 'Content-Type': 'application/json',
            // 'accept': 'application/json, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'Authorization': `Bearer ${token}` ,
          },}
    )
    .then( (response) => {
        console.log("update", response)
       return   (response?.data);
        
      })
    
      .catch( (error) => {
        console.log("22error",error);
      })
     }

     export const viewPosts = async () => {
      const token =localStorage.getItem('loginToken');
        // console.log(values,"viewPosts","valuesvaluesvalues");
        
          return axios.get(`${BaseUrl}/view?fileType=DOCUMENT&fileName=legal_document.docx&rolename=role_legal&username=praveenlegal`
          // ,{
          //   headers: {
          //       'Authorization': `Bearer ${token}` ,
          //     },}
        )
        .then( (response) => {
            console.log("viewPosts",response)
           return   (response);
            
          })
        
          .catch( (error) => {
            console.log("viewPosts error",error);
          })
         }
    