import { all, takeLatest, call, put } from "redux-saga/effects";

import { addUser, getUserStatus,addUserStatus,getUser,updateUser,updateUserStatus,deleteUser,deleteUserStatus} from "./action";

import {AddUserService ,GetUserService,UpdateUserService,deleteUserService} from "./query";

export function* getUserSaga(payload) {
  console.log("payload",payload.payload)

  try {
    const data  = yield call(AddUserService,payload.payload);
    yield put(addUserStatus(data));

    const users = yield call(GetUserService,payload.payload);
    const userdata=users
    console.log(userdata,"usd")
    yield put(getUserStatus(userdata));
    

  } catch (err) {
    console.log(err,"error123")
  
    yield put(getUserStatus(err));
  }
}
export function* getUserListSaga(payload) {
  console.log("payload",payload)

  try {
    
    const users = yield call(GetUserService,payload);
    const userdata=users
    console.log(userdata,"usd ")
    yield put(getUserStatus(userdata));
    

  } catch (err) {
    console.log(err,"error123")
  
    yield put(getUserStatus(err));
  }
}
export function* updateUserListSaga(payload) {
  console.log("payload Update",payload.payload)

  try {
    const data  = yield call(UpdateUserService,payload.payload);
    yield put(updateUserStatus(data));
    
    const users = yield call(GetUserService,payload.payload);
    const userdata=users
    console.log(userdata,"usd update")
    yield put(getUserStatus(userdata));

  } catch (err) {
    console.log(err,"error update")
  
    yield put(getUserStatus(err));
  }
}

export function* deleteUserSaga(payload) {
  console.log("payload",payload.payload)

  try {
    const data  = yield call(deleteUserService,payload.payload);
    yield put(deleteUserStatus(data));

    const users = yield call(GetUserService,payload.payload);
    const userdata=users
    console.log(userdata,"usd")
    yield put(getUserStatus(userdata));
    

  } catch (err) {
    console.log(err,"error123")
  
    yield put(getUserStatus(err));
  }
}
export default function* postsManagerSaga() {
  yield all([takeLatest(addUser, getUserSaga),takeLatest(getUser, getUserListSaga),
    takeLatest(updateUser,updateUserListSaga),takeLatest(deleteUser,deleteUserSaga)

  ]);

}

