import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../components/pages/Login/login";
import Signup from "../components/pages/Login/signup";
import Signup2 from "../components/pages/Login/signup2";
import Forgot from "../components/pages/Login/forgotpassword";
import Forgot2 from "../components/pages/Login/forgotpasswordfinal";
import Nitro from "../mainmenu/mainmenu";
import Mydevice from "../components/pages/myDevice";
import Profile from "../components/pages/profile";
import ViewNest from "../components/pages/viewNest";
import "../styles/css.css";
import "../styles/mobile.css";
import "../styles/style.css";
import "../styles/antdOverride.css";
const Routing = () => {
  return (
    <div>
      {/* <div> idaggazVhadUVHoaduzVHOUdahzvuio</div> */}
      <Router>
        {/* <Switch> */}
        {/* <Redirect from="/" to="/login" /> */}
        <Route path={["/login", "/"]} exact component={Login}></Route>
        <Route path={["/verifyEmail"]} exact component={Signup}></Route>
        <Route path={["/signup"]} exact component={Signup2}></Route>
        <Route path={["/forgotPassword"]} exact component={Forgot}></Route>
        <Route
          path={["/forgotPasswordFinal"]}
          exact
          component={Forgot2}
        ></Route>
        <Route path={["/mydevice"]} exact component={Mydevice}></Route>
        <Route path={["/profile"]} exact component={Profile}></Route>
        {/* <Route path="/changePassword" exact component={changePassword}></Route> */}
        <Route path="/nitro">
          <Nitro />
        </Route>
      </Router>
      {/* <Navbar /> */}
    </div>
  );
};

export default Routing;
