import axios from "axios";
import {BaseUrl} from "../../utils/config";
import { message} from 'antd';
const token =localStorage.getItem('loginToken');

export const AddUserService = values =>{
console.log("newuser",values);
return axios.post(`${BaseUrl}/user`, values.values,{
    headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` ,
      },}
)
.then( (response) => {
    console.log("responseuser", response)
    message.success(`User Added Successfully`);
   return response.data;
    
  })
  .catch( (error) => {
    console.log(error);
    message.error(`User failed to add`);
  });  }


  export const GetUserService = () =>{
    return   axios.get(`${BaseUrl}/users`,{
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` ,
          },}
    )
    .then( (response) => {
        console.log("reget", response)
       return response?.data;
        
      })
      .catch( (error) => {
        console.log(error);
      });  }


      export const UpdateUserService = values =>{
        console.log(" usersupdate",values);
        return axios.put(`${BaseUrl}/users`, values.values,{
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` ,
              },}
        )
        .then( (response) => {
            console.log("update", response)
           return response?.data;
            
          })
          .catch( (error) => {
            console.log(error);
          });  }


          export const deleteUserService = values =>{
            console.log("update users",values);
            return axios.delete(`${BaseUrl}/user/${values.id}`,{
                headers: {
                    // 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` ,
                  },}
            )
            .then( (response) => {
                console.log("update", response)
               return response?.data;
                
              })
              .catch( (error) => {
                console.log(error);
              });  }