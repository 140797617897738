import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
const CreateProduct = ({}) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const toSubPage = (item) => {
    history.push(item);
  };
  return (
    <>
      <div class="large-view-area">
        <h2 class="heading-page">Collections</h2>

        <div class="home-dash">
          <div>
            <a onClick={() => toSubPage(`/nitro/dashboard`)} class="white">
              <i class="fa fa-home"></i> Home
            </a>{" "}
            /
            <a onClick={() => toSubPage(`/nitro/collections`)} class="white">
              Collections
            </a>{" "}
            /
            <a href="createproduct.html" class="dull">
              Create Product
            </a>
          </div>
          {/* <!-- <a href="createuser.html" class="create-user-btn">Create User</a> --> */}
        </div>

        <form
          class="signup-form create-user-form"
          //   style="max-width: 600px;margin: auto;" style="text-align: center;"
        >
          <div class="form-head ">
            <h2>Create Collections</h2>
          </div>

          <div class="half-field">
            <div class="form-field">
              <label for="name"> Name</label>
              <input type="text" name="name" id="name" placeholder="Name" />
            </div>
            <div class="form-field">
              <label for="price">Price</label>
              <input type="text" name="price" id="price" placeholder="Price" />
            </div>
          </div>
          <div class="form-field">
            <label for="description">Description</label>
            <textarea
              name="description"
              id=""
              rows="4"
              placeholder="Description"
            ></textarea>
          </div>
          <div class="form-field">
            <label for="image">Upload Product Image</label>
            <input type="file" name="image" id="image" />
          </div>

          <div class="submit-buttons">
            <input type="submit" value="Create" class="btn-submit " />
            {/* <!-- <input type="submit" value="Create" class="btn-submit"> nbbbbb --> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateProduct;
