import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import swal from "sweetalert";
import { Form, Input, Modal, Select, Empty } from "antd";
import { connect, useSelector } from "react-redux";
import {
  setConnectedWallet,
  setStakeTokenData,
  viewNestId,
  updateUserDetailsRedux,
} from "../../redux/actions";
import axios from "axios";
import { BaseUrl } from "../../utils/config";
const ViewNest = ({ viewId }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allproducts, setAllproducts] = useState([]);
  const [enterdComments, setEnterdComments] = useState(null);
  const [aprovReject, setAprovReject] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [nestId, setNestId] = useState(null);
  // const userDetails = useSelector((state) => state.common.viewId);

  useEffect(() => {
    // $("#myTable").DataTable({});
    // const isTemp = localStorage.getItem("loginUserId");2121
    console.log("1968", location.nestId?.id);
    const nestid = location.nestId?.id;
    nestid === undefined && history.push("/nitro/nest");

    axios
      .get(`${BaseUrl}/getNestingPlaceData/${nestid}`)
      .then((response) => {
        console.log("textnest", response.data.data[0]);
        if (response) {
          setAllproducts(response.data.data[0]);
          setDocuments(JSON.parse(response.data.data[0].userFiles));
        } else {
        }
      })
      .catch((error) => {
        // swal({
        //   title: "Invalid credentials",
        //   text: "Please try again",
        //   icon: "error",
        // });
      });
  }, [location]);

  const toSubPage = (item) => {
    history.push(item);
  };
  const onReject = () => {
    const Fobj = {
      place_id: allproducts.place_id,
      comment: enterdComments,
    };
    console.log("reject", allproducts);
    axios
      .post(`${BaseUrl}/rejectKyc`, Fobj)
      .then((response) => {
        console.log("textnest", response.data);
        response?.data?.status && history.push("/nitro/nest");
        response?.data?.status
          ? swal({
              title: "success!",
              text: response?.data?.message,
              icon: "success",
            })
          : swal({
              title: "Faild!",
              text: "try again",
              icon: "warning",
            });
        // if (response) {
        //   setAllproducts(response.data.data[0]);
        // } else {
        // }
      })
      .catch((error) => {
        // swal({
        //   title: "Invalid credentials",
        //   text: "Please try again",
        //   icon: "error",
        // });
      });
  };
  const onModelOpen = (value) => {
    setIsModalVisible(true);
    setAprovReject(value);
  };
  const onAprove = () => {
    const Fobj = {
      place_id: allproducts.place_id,
      comment: enterdComments,
    };
    console.log("aprove", allproducts);
    axios
      .post(`${BaseUrl}/approveKyc`, Fobj)
      .then((response) => {
        console.log("textnest", response.data);
        response?.data?.status && history.push("/nitro/nest");
        response.data.status
          ? swal({
              title: "success!",
              text: response.data.message,
              icon: "success",
            })
          : swal({
              title: "Faild!",
              text: "try again",
              icon: "warning",
            });
        // if (response) {
        //   setAllproducts(response.data.data[0]);
        // } else {
        // }
        // window.reLoad();
      })
      .catch((error) => {
        // swal({
        //   title: "Invalid credentials",
        //   text: "Please try again",
        //   icon: "error",
        // });
      });
  };
  const onModelClose = () => {
    setIsModalVisible(false);
    if (enterdComments != null) {
      aprovReject == "aprove" && onAprove();
      aprovReject == "reject" && onReject();
    }
  };
  const onComments = (value) => {
    setEnterdComments(value);
  };
  const onFinish = (e) => {
    onModelClose();
  };
  console.log("allproductsallproducts", documents);
  return (
    <>
      <div class="large-view-area">
        <h2 class="heading-page">Nest Details</h2>

        <div class="home-dash">
          <div>
            <a onClick={() => toSubPage(`/nitro/dashboard`)} class="white">
              <i class="fa fa-home"></i> Home
            </a>{" "}
            /
            <a onClick={() => toSubPage(`/nitro/nest`)} class="white">
              Nest
            </a>{" "}
            /<a class="dull"> Nest Details</a>
          </div>
          {/* <!-- <a href="createuser.html" class="create-user-btn">Create User</a> --> */}
        </div>
      </div>

      <section class="content">
        <div class="box">
          <div class="box-body">
            <form
              method="POST"
              action="customers.php"
              accept-charset="UTF-8"
              class="form-horizontal"
            >
              <input
                name="_token"
                type="hidden"
                value="dGNYCYw78TEyMiRXLOLClOpxw204C8yRMhBUJBdU"
              />
              <h4 class="box-title">Nesting Details</h4>
              <hr />
              <div class="row mrb20">
                <div class="col-md-6">
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Email
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.email}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Address
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.address}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Latitude{" "}
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Longitude
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lang}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Type of miner
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.miner_name}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Status
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.verification_status
                        ? allproducts.verification_status
                        : "pending"}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h4 class="box-title"> Kyc Details</h4>
              <hr />
              <div class="row mrb20 billing-adr">
                <div class="col-md-6">
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Name
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.firstName} {allproducts.lastName}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Mobile number
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.mobileNumber}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      D.O.B
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.dob}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Verification status
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.verification_status
                        ? allproducts.verification_status
                        : "pending"}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Place Name
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.place_name}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Door / Flat number{" "}
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.doorOrFlatNumber}
                    </div>
                  </div>

                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Apartment/Area name{" "}
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.apartmentOrAreaName}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Landmark{" "}
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.landmark}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      City
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.city}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      District and state
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.districtAndState}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      State
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.state ? allproducts.state : "..."}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Country
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.country}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Country of residence
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.countryOfResidence}
                    </div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Pincode
                    </label>
                    <div class="col-md-8  colorLight">
                      {allproducts.pincode}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h4 class="box-title"> Documents</h4>
              <hr />
              <div class="row user-table mt-6">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <table
                    class="table table-fluid"
                    id="myTable"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th class="rd">Category</th>
                        <th class="rd">Document Type</th>
                        <th class="right-field">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {documents ? (
                        documents.map((item) => {
                          return (
                            <tr>
                              <td class="r">{item.category}</td>
                              <td class="r">{item.documentType}</td>
                              <td class="right-field">
                                {" "}
                                <a
                                  onClick={() => window.open(item.file)}
                                  class="create-user-btn"
                                >
                                  view
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="form-head">
                          <td colSpan={3}>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div class="col-md-3"></div>
              </div>
              {/* <div class="row mrb20 billing-adr">
                <div class="col-md-4">
                  <img
                    src={allproducts.selfie}
                    alt="selfie"
                    className="proffImges"
                  ></img>
                </div>
                <div class="col-md-4">
                  <img
                    src={allproducts.idProof}
                    alt="id proff"
                    className="proffImges"
                  ></img>
                </div>
                <div class="col-md-4">
                  <img
                    src={allproducts.addressProof}
                    alt="Address proff"
                    className="proffImges"
                  ></img>
                </div>
              </div> */}
              <hr />
              {/* <h4 class="box-title"> Shipping Address</h4>
              <hr />
              <div class="row mrb20 ship-adr">
                <div class="col-md-6">
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Customer Name
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Contact Person
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Email Address
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      PAN No.
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Address line 2
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      City
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      State
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Location Code
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Contact Number
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      GST No.
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Address line 1
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Address line 3
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      Pin Code
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                  <div class="form-group mrb20">
                    <label for="" class="text-left col-md-4">
                      State Code
                    </label>
                    <div class="col-md-8  colorLight">{allproducts.lat}</div>
                  </div>
                </div>
              </div> */}

              <p align="center">
                {/* <a onClick={() => onComments()} class="create-user-btn">
                  Comments
                </a>{" "} */}
                <a
                  onClick={() => onModelOpen("aprove")}
                  class="create-user-btn"
                >
                  Approve
                </a>{" "}
                <a
                  onClick={() => onModelOpen("reject")}
                  class="create-user-btn"
                >
                  Reject
                </a>
              </p>
            </form>
          </div>
        </div>
      </section>
      <Modal
        visible={isModalVisible}
        footer={null}
        centered
        onCancel={() => onModelClose()}
        // width={300}
      >
        <div class="form-head ">
          <h2>Comments</h2>
        </div>
        {/* <form
          class="signup-form create-user-form"

          //   style="max-width: 600px;margin: auto;" style="text-align: center;"
        > */}
        {/* <div class="form-head ">
            <h2>Comments</h2>
          </div> */}

        <div class="half-field">
          <div class="form-field">
            <label for="name"> Enter Comments</label>
            <textarea
              type="text"
              name="comments"
              id="name"
              placeholder="Name"
              onChange={(e) => onComments(e.target.value)}
            />
          </div>
        </div>

        {/* <!-- <div class="show-later"> --> */}

        {/* <!-- </div> --> */}

        <div class="submit-buttons">
          <input
            type="submit"
            value="submit"
            class="btn-submit "
            onClick={onFinish}
          />
          {/* <!-- <input type="submit" value="Create" class="btn-submit"> nbbbbb --> */}
        </div>
        {/* </form> */}
        {/* <div className="row d-flex justify-content-center">
          {" "}
          <button class="create-user-btn" onClick={onModelClose}>
            
          </button>
        </div> */}
      </Modal>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    viewId: (payload) => dispatch(viewNestId(payload)),
  };
};

export default connect(null, mapDispatchToProps)(ViewNest);
// export default ViewNest;
