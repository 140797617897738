import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

const Users = ({}) => {
  const { url } = useRouteMatch();

  const history = useHistory();

  const toSubPage = (item) => {
    history.push(item);
  };
  return (
    <>
      <div class="large-view-area">
        <h2 class="heading-page">Users</h2>

        <div class="home-dash">
          <div>
            <a onClick={() => toSubPage(`/nitro/dashboard`)} class="white">
              <i class="fa fa-home"></i> Home
            </a>{" "}
            /
            <a href="users.html" class="dull">
              Users
            </a>
          </div>
          <a
            onClick={() => toSubPage(`/nitro/users/createuser`)}
            class="create-user-btn"
          >
            Create User
          </a>
        </div>

        <div class="user-table mt-6">
          <table class="table table-fluid" id="myTable">
            <thead>
              <tr>
                <th class="rd">Name</th>
                <th>Email address</th>
                <th>Referal code</th>
                <th class="right-field">Phone Number</th>
                <th>Country</th>
                <th class="right-field">No.of miner orderd</th>
                <th class="right-field">Amount Paid</th>
                <th class="right-field">Pending Balance</th>
                <th class="right-field">Act</th>
                {/* <!-- <th>Actions</th> --> */}
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>John Smith</td>
                <td class="r">nitro@network.com</td>
                <td class="r">0f3er5</td>
                <td class="right-field ph-number">+91 9876543210</td>
                <td>India</td>
                <td class="right-field">5</td>
                <td class="right-field">$ 1800</td>
                <td class="right-field">$ 200</td>
                <td class="right-field">
                  <a href="#" class="edit-btn">
                    Edit
                  </a>
                  <a href="#" class="delete-btn">
                    delete
                  </a>
                </td>
              </tr>
              <tr>
                <td>John Smith</td>
                <td class="r">nitro@network.com</td>
                <td class="r">0f3er5</td>

                <td class="right-field ph-number">+91 9876543210</td>
                <td>India</td>
                <td class="right-field">5</td>
                <td class="right-field">$ 1800</td>
                <td class="right-field">$ 200</td>
                <td class="right-field">
                  <a href="#" class="edit-btn">
                    Edit
                  </a>
                  <a href="#" class="delete-btn">
                    delete
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Users;
