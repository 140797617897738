import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { Form, Input, Modal, Select, Button } from "antd";
// import "../styles/style.css";

import swal from "sweetalert";
import "antd/dist/antd.css";
import { useHistory, Link, useLocation } from "react-router-dom";
import { BaseUrl } from "../../../utils/config";
// import { Base64 } from 'js-base64';
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const Signup = () => {
  // const [isPass, SetIsPass] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isTemp, setTemp] = useState(false);
  const navigate = useHistory();
  const history = useHistory();
  const location = useLocation();
  const [refferal, setRefferal] = useState("");

  const [form] = Form.useForm();
  // var bcrypt = require("bcryptjs");
  useEffect(async () => {
    // loadCaptchaEnginge(6,'#11132800','#ffffff');
    // setIsCaptcha(false)
    // let path=location.pathname;
    // let temp = path.substr(path.lastIndexOf("/")+1);
    // temp==="verifyEmail"?setRefferal(""):setRefferal(Base64.decode(temp));
  }, []);

  const onFinish = (values) => {
    const Fobj = {
      email: values.email,
    };
    console.log("bcrypt", Fobj);
    if (!isEmail) {
      swal({
        // title: "User does not exist!",
        text: "Please enter the Credentials",
        icon: "error",
      });
    } else {
      var axios = require("axios");
      var data = JSON.stringify({
        email: values.email,
      });

      var config = {
        method: "post",
        url: `${BaseUrl}/validateemail`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
          });
        })
        .catch(function (error) {
          console.log(error.response);
          swal({
            // title: "User Already  exist!",
            text: error.response.data.message,
            icon: "warning",
          });
        });
    }
  };

  const toSignup = () => {
    history.push("/verifyEmail");
  };
  const toforgot = () => {
    history.push("/forgotPassword");
  };
  const toLogin = () => {
    navigate("/login");
  };

  return (
    <div>
      {/* <GlobalStyles/> */}

      <div class="form-container">
        <div class="logo">
          <img src="./images/nitro-white.png" alt="" id="logo" />
        </div>

        <Form
          name="contactForm"
          id="contact_form"
          className=""
          action=""
          autocomplete="off"
          onFinish={onFinish}
        >
          <div class="form-head">
            <h2>Signup</h2>
          </div>

          <div class="form-field">
            <label for="email">E-mail</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <div class="form-f">
                <Input
                  id="email"
                  placeholder="Enter E-mail"
                  onChange={(e) => {
                    setIsEmail(e.target.value);
                  }}
                  className="form-control"
                />
                <i class="fa fa-user icon"></i>
              </div>
            </Form.Item>
          </div>

          <div class="submit-buttons">
            <input type="submit" value="Verify" class="btn-submit" />
          </div>
          <div class="create-account">
            Already have an account ?<a onClick={toLogin}>Signin</a>
          </div>

          <div class="copyright">
            <p>&copy; 2021 NITRO NETWORK PVT. LTD. All Rights Reserved.</p>
          </div>
        </Form>
      </div>

      {/* <Footer /> */}
    </div>
  );
};
export default Signup;
