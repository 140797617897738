import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { Form, Input, Checkbox, Select, Button } from "antd";
import { BaseUrl } from "../../../utils/config";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const Signup2 = (tcPropos) => {
  const history = useHistory();
  const navigate = useHistory();
  const location = useLocation();
  const [passErrorMsg, setPassErrorMsg] = useState(null);
  const [isName, setIsName] = useState(null);
  const [isEmail, setIsEmail] = useState(null);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [isPass, setIsPass] = useState(null);
  const [isCPass, setIsCPass] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    console.log("sandeep", location.pathname); // result: '/secondpage'
    window.scrollTo(0, 0);
    let path = location.pathname;
    let temp = path.substring(path.lastIndexOf("/") + 1);
    //  Base64.decode(this.props.match.params.id);

    if (location.state) {
      setIsEmail(location.state?.email1);
    }
    // location.state && form.setFieldsValue({
    //   email: location.state?.email1,
    // });
    // tcPropos?.location?.tCprops && form.setFieldsValue({
    //   email: tcPropos.location.tCprops.email,
    //   name: tcPropos.location.tCprops.name,
    //   password: tcPropos.location.tCprops.passWord,
    //   cPassword: tcPropos.location.tCprops.cPassword,

    // });
    // if (tcPropos?.location?.tCprops) {
    //   setIsEmail(tcPropos.location.tCprops.email);
    //   setIsName(tcPropos.location.tCprops.name)
    //   setIsPass(tcPropos.location.tCprops.passWord);
    //   setIsCPass(tcPropos.location.tCprops.cPassword);
    //   setCheckedTerms(tcPropos.location.tCprops.checkedTerms)
    // } else {

    callForPreParams(temp);
    // }
  }, [location]);
  const onChangeCheck = (e) => {
    setCheckedTerms(e.target.checked);
  };
  const verifyPassWord = () => {
    if (isPass != null && isCPass != null) {
      isPass != isCPass
        ? setPassErrorMsg("The two passwords that you entered do not match!")
        : setPassErrorMsg(null);
    }
  };
  const callForPreParams = (temp) => {
    axios
      .get(`${BaseUrl}/verifymail/${temp}`)
      .then((response) => {
        console.log("response", response?.data);
        if (response) {
          console.log("response", response?.data);

          swal({
            title: "Success!",
            text: response?.data?.message,
            icon: "success",
          });
          setIsEmail(response?.data?.data);
          form.setFieldsValue({
            email: response?.data?.data,
          });
        } else {
          swal({
            title: "Invalid Credentials",
            text: "Please try again",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error.message);

        swal({
          title: "Invalid Credentials",
          text: "Please try again",
          icon: "error",
        });
      });
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    const Fobj = {
      password: values.cPassword,
      email: values.email,
      name: values.name,
    };
    console.log("Received values of form: ", Fobj);
    if (
      values.password == null ||
      values.password == "" ||
      values.cPassword == null ||
      values.email == null ||
      isName == null ||
      values.cpassword == "" ||
      isPass != isCPass ||
      !checkedTerms
    ) {
      swal({
        title: "",
        text: "Please enter the Credentials",
        icon: "error",
      });
    } else {
      axios
        .post(`${BaseUrl}/signup`, Fobj)
        .then((response) => {
          console.log("response", response);
          if (response) {
            // "Your Federal Account Created Successfully"
            // message.success(`Congratulations  Your Federal Account Created Successfully`,3)

            swal({
              title: "Congratulations!",
              text: "Your  account created successfully.",
              icon: "success",
            });
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else {
            console.log("response");

            swal({
              title: "Invalid Credentials",
              text: "Please try again",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error.message);

          swal({
            title: error.message,
            text: "Please try to login",
            icon: "warning",
          });
        });
    }
  };

  const toLogin = () => {
    navigate("/login");
  };

  const toSignup = () => {
    navigate("/verifyEmail");
  };
  const toforgot = () => {
    navigate("/forgotPassword");
  };

  return (
    <div>
      <div class="form-container">
        <div class="logo">
          <img src="./images/nitro-white.png" alt="" id="logo" />
        </div>

        <Form
          id="contact_form"
          action=""
          autocomplete="off"
          form={form}
          name="control-hooks"
          onFinish={onFinish}
        >
          <div class="form-head">
            <h2>Signup</h2>
          </div>

          <div class="form-field">
            <label for="email">E-mail</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <div class="form-f">
                <Input
                  id="email"
                  className="form-control"
                  placeholder="Enter E-mail"
                  onChange={(e) => {
                    setIsEmail(e.target.value);
                  }}
                />
                <i class="fa fa-user icon"></i>
              </div>
            </Form.Item>
          </div>
          <div class="form-field">
            <label for="email">Name</label>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <div class="form-f">
                <Input
                  id="name"
                  placeholder="Enter name"
                  className="form-control"
                  onChange={(e) => {
                    setIsName(e.target.value);
                  }}
                />
                <i class="fa fa-user icon"></i>
              </div>
            </Form.Item>
          </div>
          <div class="form-field">
            <label for="password">Password</label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <div class="form-f">
                <Input.Password
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setIsPass(e.target.value);
                  }}
                  onMouseOut={() => {
                    verifyPassWord();
                  }}
                />
                <i class="fa fa-key icon"></i>
              </div>
            </Form.Item>
            {/* <Input className="form-control"
      size="large"
      type="password"
      placeholder="Password"
      onChange={(e) => { setIsPass(e.target.value) }}
      onMouseOut={() => { verifyPassWord() }}
    /> */}
          </div>
          <div class="form-field">
            <label for="cPassword">Confirm Password</label>
            <Form.Item
              name="cPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm password!",
                },
              ]}
            >
              <div class="form-f">
                <Input.Password
                  className="form-control"
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    setIsCPass(e.target.value);
                  }}
                  onMouseOut={() => {
                    verifyPassWord();
                  }}
                />
                <i class="fa fa-key icon"></i>
              </div>
            </Form.Item>
            <p className="passErrorMsg">{passErrorMsg}</p>
          </div>
          <div class="checkbox">
            <Checkbox
              onChange={onChangeCheck}
              checked={checkedTerms}
            ></Checkbox>

            <label for="terms">
              {" "}
              &nbsp;&nbsp; <span className="colorWhite">I agree to </span>
              <a href="https://nitro.network/terms-and-conditions">
                {" "}
                Terms & Conditions
              </a>
              {/* <Link to={ 
                  {
                    pathname: '/t&c',
                    signprops:{
                      email:isEmail,
                      name:isName,
                      passWord:isPass,
                      cPassword:isCPass,
                      checkedTerms:checkedTerms,
                    }
                  }
                }
               ><div >terms & condition</div></Link> */}
            </label>
          </div>

          <div class="submit-buttons">
            <input type="submit" value="Submit" class="btn-submit " />
          </div>

          <div class="create-account">
            Already have an account ?<a onClick={toLogin}>Sign in</a>
          </div>

          <div class="copyright">
            <p>&copy; 2021 NITRO NETWORK PVT. LTD. All Rights Reserved.</p>
          </div>
        </Form>
      </div>

      {/* <Footer /> */}
    </div>
  );
};
export default Signup2;
