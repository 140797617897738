import axios from "axios";
import {BaseUrl} from "../../utils/config";
import {message } from "antd";
const token =localStorage.getItem('loginToken');
export const loginUserService = values => axios.post(`${BaseUrl}/login`, values)
.then( (response) => {
    console.log("responselogin", response.data)
 
    if (response.data.currentAttemptCount>0){
      response.data.currentAttemptCount>=5? message.error(`invalid credentials,you have no more ateempts to try. please contact Admin.`):
      message.error(`invalid credentials, you have ${Number(5)-Number(response.data.currentAttemptCount)} more ateempts left.`);
    }
    return response.data;
  })
  .catch( (error) => {
    console.log(error);
  });  

  export const logoutUserService = values => axios.get(`${BaseUrl}/user/logout`,{
    headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` ,
      },
  })
  .then( (response) => {
      console.log("reslogout", response.data)
     return response.data;
     
      
    })
    .catch( (error) => {
      console.log("errorlogout",error);
    });  