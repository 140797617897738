import { createAction } from "redux-actions";

export const  getUser = createAction("GET_USER");
export const  addUser = createAction("ADD_USER");
export const  updateUser= createAction("UPDATE_USER");
export const  updateUserStatus= createAction("UPDATE_USER_STATUS");
export const  deleteUser= createAction("DELETE_USER");
export const  deleteUserStatus= createAction("DELETE_USER_STATUS");
export const getUserStatus = createAction("GET_USER_STATUS");
export const addUserStatus = createAction("ADD_USER_STATUS");
export const createUserLocaly = createAction("CREATE_USER_LOCALY");
export const statusUnset = createAction("POST_UNSET");
