import React from "react";
import MydeviceMenu from "../../mainmenu/mydeviceMenu";
import User from "../../images/user.png";

const Profile = ({}) => {
  return (
    <>
      <div class="dashboard-container">
        <MydeviceMenu />
        <div class="large-view-area">
          <div class="profile-container">
            <form>
              <div class="profile-pic">
                <img src={User} alt="" id="user-pic" />
              </div>
              <div class="form-field">
                <label for="email">Name</label>
                {/* <!-- <input type="email" name="email" id="email" placeholder="Email@adress.com"> -->
                <!-- <a href="#" class="verify-btn">Verify</a> --> */}
                <p>John Smith</p>
              </div>
              <div class="form-field">
                <label for="email">Email</label>
                {/* <!-- <input type="email" name="email" id="email" placeholder="Email@adress.com"> -->
                <!-- <a href="#" class="verify-btn">Verify</a> --> */}
                <p>Email@address.com</p>
              </div>
              <div class="form-field">
                <label for="otp">Phone Number</label>
                {/* <!-- <input type="text" name="otp" id="otp" placeholder="000000"> */}
                {/* <a href="#" class="verify-btn">Confirm OTP</a> --> */}
                <p>+91 9876543210</p>
              </div>

              <div class="form-field">
                <label for="pass">Country</label>
                {/* <!-- <input type="password" name="pass" id="pass"> --> */}
                <p>India</p>
              </div>

              <div class="submit-buttons">
                <input
                  type="reset"
                  value="Go Home"
                  class="btn-submit first-btn"
                />
                <input
                  type="submit"
                  value="Update Profile"
                  class="btn-submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
