import { createAction } from "redux-actions";

export const  getPosts = createAction("GET_POSTS");
export const getPostsStatus = createAction("GET_POSTS_STATUS");
export const  renamePosts = createAction("RENAME_POSTS");
export const renamePostsStatus = createAction("RENAME_POSTS_STATUS");
export const  downloadPosts = createAction("DOWNLOAD_POSTS");
export const downloadPostsStatus = createAction("DOWNLOAD_POSTS_STATUS");
export const  viewPosts = createAction("VIEW_POSTS");
export const viewPostsStatus = createAction("VIEW_POSTS_STATUS");
export const  uploadPosts = createAction("UPLOAD_POSTS");
export const uploadPostsStatus = createAction("UPLOAD_POSTS_STATUS");
export const createPostLocaly = createAction("CREATE_POST_LOCALY");
export const statusUnset = createAction("POST_UNSET");
