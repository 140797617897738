import { handleActions } from "redux-actions";
import {
  getLogin,
  getLoginStatus,
  getLogout,
  getLogoutStatus
} from "./action";

const DEFAULT_STATE = {
  response:{}
  
};

const handlers = {
  [getLogin]: state => ({ ...state, status: "PENDING" }),

  [getLoginStatus]: (state, action) => {
    if (action.error) {
      return { ...state, status: "ERROR" };
    }
    console.log(action,"action123")
    window.localStorage.setItem("login",action.payload.username);
    window.localStorage.setItem("loginRole",action.payload.authorities[0].authority);
    window.localStorage.setItem("loginGender",action.payload.gender);
    window.localStorage.setItem("loginId",action.payload.id);
    window.localStorage.setItem("loginToken",action.payload.token);
  
    return { ...state, response:action  };
    // let token = localStorage.getItem('login');

  },
  [getLogout]: state => ({ ...state, status: "PENDING" }),

  [getLogoutStatus]: (state, action) => {
    if (action.error) {
      return { ...state, status: "ERROR" };
    }
    localStorage.clear();
    return { ...state, response:action  };
  },

};

export default handleActions(handlers, DEFAULT_STATE);
